import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Ig from "../assets/img/instagramIcon.svg";
import Fb from "../assets/img/facebookIcon.svg";
import Li from "../assets/img/linkedinIcon.svg";

const FooterInner = styled.footer`
  background: #0E1E35;
  color: #fff;
  padding: 60px 0;

  .footerWrapper {
    @media (min-width: 900px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 1150px;
      margin: 0 auto;
      /* border-top: 2px solid #444444; */
      padding-top: 0;
      width: calc(100% - 50px);
    }
  }

  ul {
    width: calc(100% - 80px);
    margin: 0 auto;
    padding-bottom: 50px;

    @media (min-width: 900px) {
      padding-bottom: 40px;
    }

    &:nth-child(1) {
      @media (min-width: 900px) {
        order: 1;
        width: 180px;
      }
    }

    &:nth-child(2) {
      @media (min-width: 900px) {
        order: 3;
        width: 180px;
      }
    }

    &:nth-child(3) {
      @media (min-width: 900px) {
        order: 2;
        width: 340px;
      }
    }

    li {
      font-family: Visuelt-Regular, Helvetica;
      font-size: 16px;
      color: #fff;
      text-align: center;
      list-style: none;
      margin-bottom: 5px;

      a {
        font-family: Visuelt-Regular, Helvetica;
        font-size: 16px;
        color: #fff;
        text-align: center;
        text-decoration: none;
      }

      h4 {
        font-family: Visuelt-Bold, Helvetica;
        font-size: 18px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 22px;
      }
    }

    .footerPopisText {
      font-family: Visuelt-Regular, Helvetica;
      font-size: 16px;
      color: #A5A5A5;
      text-align: center;
      margin-top: 25px;
    }

    .socials {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
      margin-top: 25px;
      max-width: 250px;

      a {
        &:hover {
          img {
            opacity: 100%;
          }
        }

        img {
          opacity: 80%;
        }
      }
    }
  }

  .copyright {
    font-family: Visuelt-Medium, Helvetica;
    font-size: 14px;
    color: gray;
    text-align: center;
    line-height: 20px;
    border-top: 1px solid #303E52;
    width: calc(100% - 80px);
    margin: 0 auto;
    padding-top: 30px;
    max-width: 1150px;
  }
`;

const Footer = ({ children }) => {
  return (
    <FooterInner>
      <div className="footerWrapper">
        <ul>
          <li>
            <h4>Contacto</h4>
          </li>
          <li>
            <a href="tel:00420315558136">+420 XXX XXX XXX</a>
          </li>
          <li>
            <a href="mailto:podpora@goodbye.cz">soporte@etanatorio.es</a>
          </li>
          <li className="footerPopisText">Estamos a su disposición las 24 horas del día, los 7 días de la semana.</li>
          <li className="socials">
            <a target="_blank" href="https://www.instagram.com/goodbye.cz/">
              <img src={Ig} alt="Instagram ikona" />
            </a>
            <a target="_blank" href="https://www.facebook.com/czgoodbye/">
              <img src={Fb} alt="Facebook ikona" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/goodbye-cz/">
              <img src={Li} alt="LinkedIn ikona" />
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <h4>Menu</h4>
          </li>
          <li>
            <Link to="/">Servicio funerario</Link>
          </li>
          <li>
            <Link to="/prihlasit-se">Entrar en el sistema</Link>
          </li>
          <li>
            <Link to="/o-nas">Sobre nosotros</Link>
          </li>
          <li>
            <Link to="/obchodni-podminky">Términos y condiciones</Link>
          </li>
        </ul>
        <ul>
          <li>
            <h4>eTanatorio.es</h4>
          </li>
          <li className="footerPopisText">Operador:</li>
          <li>Goodbye s.r.o.</li>
          <li>IČO: 08319588</li>
          <li>DIČ: CZ08319588</li>
          <li className="footerPopisText">Titular de una licencia comercial para explotar un servicio funerario.</li>
        </ul>
      </div>
      <div className="copyright">Copyright © 2022</div>
      {children}
    </FooterInner>
  );
}

export default Footer;
